export * from './outsideHeaderEvents';
export type MembershipType =
  | 'guest'
  | 'free_membership'
  | 'trial_membership'
  | 'trial_membership_canceled'
  | 'paid_membership'
  | 'paid_membership_incomplete'
  | 'paid_membership_canceled'
  | 'paid_membership_past_due';
export type Membership = {
  adFree: boolean;
  membershipType: MembershipType;
  memberExclusiveLevel:
    | 'full_access'
    | 'vertical_access'
    | 'registered_access'
    | 'None';
  createdAt: string | null;
  updatedAt: string | null;
  expiresAt: string | null;
  cancelledAt: string | null;
  trialRedeemed: boolean;
  isUpgraded: boolean;
};
export type Profile = {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  gender: 'male' | 'female' | 'other' | 'notsay' | null;
  birthday: string | null;
  countryCode: string | null;
  stateCode: string | null;
  zipCode: string | null;
};
export enum UserTypes {
  OutsidePlus = 'OutsidePlus',
  OPlus = 'OPlus',
  BrandPass = 'BrandPass',
  Free = 'Free'
}
export type Subscription = {
  name: string;
  shortname:
    | 'active_pass_full'
    | 'backpacker_pass'
    | 'beta_pass'
    | 'clean_eating_pass'
    | 'climbing_pass'
    | 'outside_pass'
    | 'oxygen_pass'
    | 'podium_runner_pass'
    | 'ski_pass'
    | 'trail_runner_pass'
    | 'triathlete_pass'
    | 'vegetarian_times_pass'
    | 'velo_news_pass'
    | 'womens_running_pass'
    | 'yoga_journal_pass';
  plan: {
    shortname:
      | 'active_pass_full_yearly'
      | 'backpacker_pass_yearly'
      | 'beta_pass_yearly'
      | 'clean_eating_pass_yearly'
      | 'climbing_pass_yearly'
      | 'outside_pass_yearly'
      | 'oxygen_pass_yearly'
      | 'podium_runner_pass_yearly'
      | 'ski_pass_yearly'
      | 'trail_runner_pass_yearly'
      | 'triathlete_pass_yearly'
      | 'vegetarian_times_pass_yearly'
      | 'velo_news_pass_yearly'
      | 'womens_running_pass_yearly'
      | 'yoga_journal_pass_yearly';
    billing: string;
  };
};
export type RawUserProfile = {
  uuid: string;
  first_name: string;
  last_name: string;
  email: string;
  avatar_url: string;
  gender: 'male' | 'female' | 'other' | 'notsay' | null;
  birthday: string;
  country_code: string;
  state_code: string;
  zip_code: string;
  connected_apps: {
    strava: boolean;
  };
  membership: {
    ad_free: boolean;
    subscription: Subscription | null;
    membership_type: MembershipType;
    created_at: string;
    updated_at: string | null;
    expires_at: string | null;
    canceled_at: string | null;
    stripe_subscription_status: string | null;
    trial_redeemed: boolean;
    is_upgraded: boolean;
  };
  newsletter_subscribed: boolean;
  email_confirmed: boolean;
  signup_source: string;
  exclude_from_analytics: boolean;
  tos_accepted: boolean;
  activated: boolean;
  member_exclusive_level:
    | 'full_access'
    | 'vertical_access'
    | 'registered_access'
    | 'None';
  key: string;
};
export type AuthState = {
  isLoggedIn: boolean;
  accessToken?: string | null;
  refreshToken?: string | null;
  rivtToken: string | null;
  userType: UserTypes | null;
  profile: Profile | null;
  membership: Membership | null;
  subscription: Subscription | null;
  emailConfirmed: boolean;
  newsletterSubscribed: boolean;
  excludeFromAnalytics: boolean;
  tosAccepted: boolean;
  activated: boolean;
  discountCode: string | null;
  rawUserProfile: RawUserProfile | null;
  stripeCustomerId: string | null;
};
