import { Context, useContext } from 'react';
import { UserTypes } from 'types/WebMicrofrontends';
import { useAuthService } from '@pocketoutdoormedia/auth-state-service';

import { AuthContext } from './AuthContext';

interface UseUserType {
  userType: UserTypes | null;
  isOutsidePlusUser: boolean;
  isBrandPassUser: boolean;
  isFreeUser: boolean;
  isAnonymousUser: boolean;
  isLoggedIn: boolean;
  uuid: string | null;
}

/*
OutsideLearn uses AuthService to determine user auth state.
OutsideWatch currently uses AuthContext. As to not introduce breaking changes
this hook was made to work with both contexts, depending on availability of the
AuthService. The try/catch block implements useUserType interface for the apps without AuthService,
otherwise there's a fallback to a default implementation using AuthContext.
 */

export const useUserType = (): UseUserType => {
  const authContext = useContext(AuthContext);

  // UseAuthService throws if AuthService is out of bounds
  try {
    const authService = useAuthService();

    // If uuid is not present default to LegacyAuthProvider
    if (authContext.state?.rawUserProfile?.uuid) {
      throw new Error('Use AuthContext');
    }
    if (authService.isLoading || !authService.userState.isAuthenticated)
      return DEFAULT_USER_TYPE;

    const {
      userData: { isSubscriber }
    } = authService.userState;
    return {
      userType: isSubscriber ? UserTypes.OPlus : UserTypes.Free,
      uuid: null,
      isOutsidePlusUser: isSubscriber,
      isAnonymousUser: false,
      isLoggedIn: true,
      isFreeUser: !isSubscriber,
      isBrandPassUser: isSubscriber
    };
  } catch (err) {
    return legacyContextImplementation(authContext);
  }
};

type AuthContextValueType = typeof AuthContext extends Context<infer T>
  ? T
  : never;

const legacyContextImplementation = (
  authContext: AuthContextValueType
): UseUserType => {
  const { userType, isLoggedIn } = authContext.state;

  const isOutsidePlusUser =
    userType === UserTypes.OutsidePlus || userType === UserTypes.OPlus;
  const isBrandPassUser = userType === UserTypes.BrandPass;
  const isFreeUser = userType === UserTypes.Free;
  const isAnonymousUser = userType === null;
  const uuid = authContext.state.rawUserProfile?.uuid;

  return {
    userType,
    isOutsidePlusUser,
    isBrandPassUser,
    isFreeUser,
    isAnonymousUser,
    isLoggedIn,
    uuid
  };
};

const DEFAULT_USER_TYPE = {
  userType: null,
  isAnonymousUser: true,
  isFreeUser: false,
  isLoggedIn: false,
  isBrandPassUser: false,
  uuid: null,
  isOutsidePlusUser: false
};
