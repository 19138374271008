import { AnalyticsEvent } from './analyticsEvents';
import { AuthEvent } from './authEvents';
import { UXEvent } from './uxEvents';

export * from './authEvents';
export * from './analyticsEvents';
export * from './uxEvents';
export type OutsideHeaderEvent<T> = {
  isOutgoing?: boolean;
} & T;
export type EventTypeGuard = AuthEvent | AnalyticsEvent | UXEvent;
export enum OutsideHeaderEventDomain {
  ANALYTICS = 0,
  AUTH = 1,
  UX = 2
}
export type HeaderEventHandlerFunction = (
  event: OutsideHeaderEvent<EventTypeGuard>
) => void;
export type SubscribeFunction = (
  key: string,
  listener: HeaderEventHandlerFunction
) => void | null;
export type DispatchFunction = (
  key: string,
  event: OutsideHeaderEvent<EventTypeGuard>
) => void;
export type OutsideHeaderWindowObject = {
  isReady: boolean;
  isMounted: boolean;
  subscribe: SubscribeFunction;
  dispatch: DispatchFunction;
};
export enum BroadcastEventType {
  BROADCAST_READY_STATE = 'BROADCAST_READY_STATE'
}
