/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, FC } from 'react';
import { ApolloProvider } from '@apollo/client';

import { createApolloClient, Endpoints } from './createApolloClient';

const endpoints: Endpoints = {
  graphQL: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT
};

export const FederationProvider: FC = ({ children }) => {
  const store = useMemo(
    () => createApolloClient(endpoints),
    [endpoints.graphQL]
  );

  return <ApolloProvider client={store}>{children}</ApolloProvider>;
};

export default FederationProvider;
