import { useReducer, FC, useMemo, useEffect } from 'react';
import {
  OutsideHeaderEvent,
  OutsideHeaderEventDomain,
  EventTypeGuard,
  AuthEventID,
  AuthEvent,
  AuthState
} from 'types/WebMicrofrontends';

import {
  AuthContext,
  AuthContextReducer,
  initialState,
  setUserProfile
} from './AuthContext';
import { HEADER_KEY } from './constants';

export const AuthProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(AuthContextReducer, initialState);
  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  useEffect(() => {
    if (value.state.headerIsReady) {
      window.OutsideHeader.subscribe(
        HEADER_KEY,
        (event: OutsideHeaderEvent<EventTypeGuard>) => {
          if (
            event.domain === OutsideHeaderEventDomain.AUTH &&
            event.id === AuthEventID.AUTH_STATE_UPDATE
          ) {
            dispatch(
              setUserProfile(
                (event as OutsideHeaderEvent<AuthEvent>).payload as AuthState
              )
            );
          }
        }
      );
    }
  }, [value.state.headerIsReady]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
