import { ReactElement } from 'react';

export type HeadingProps = {
  classes?: string;
  onClick?: VoidFunction;
  headingText: string | ReactElement;
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  [x: string]: unknown;
};

const HeadingBase = ({ tag, classes, headingText, ...rest }: HeadingProps) => {
  const Tag = tag as keyof JSX.IntrinsicElements;

  return (
    <Tag className={classes} {...rest}>
      {headingText}
    </Tag>
  );
};

export const H1 = (props: HeadingProps) => <HeadingBase tag="h1" {...props} />;
export const H2 = (props: HeadingProps) => <HeadingBase tag="h2" {...props} />;
export const H3 = (props: HeadingProps) => <HeadingBase tag="h3" {...props} />;
export const H4 = (props: HeadingProps) => <HeadingBase tag="h4" {...props} />;
export const H5 = (props: HeadingProps) => <HeadingBase tag="h5" {...props} />;
export const H6 = (props: HeadingProps) => <HeadingBase tag="h6" {...props} />;
